.companies-main {
  flex: 3;
  background-color: #fff;
  border-radius: 20px;
  margin-left: 20px;
}

.companies-page {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 2rem;
  background-color: #f5f5f5;
  height: calc(100vh - 110px);
}

.company-name {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.no-data {
  text-align: center;
}

.create-company-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

.edit-company-box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.edit-company-box label {
  display: block;
  margin-bottom: 10px;
}

.edit-company-box input[type="text"],
.edit-company-box textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  box-sizing: border-box;
}

.edit-company-box button {
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-company-box button:last-child {
  background-color: #ccc;
  color: black;
}