/* src/components/newVacancyFlow/ReviewStep.css */

.review-step {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .review-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  
  .review-section h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
  }
  
  .review-section p {
    margin: 0;
    font-size: 1rem;
    color: #555;
  }
  
  .review-section button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    text-decoration: underline;
  }
  
  .review-section button:hover {
    text-decoration: none;
  }
   
  .question {
    margin-top: 1rem;
  }
  
  .question ul {
    margin: 0.5rem 0 0 1rem;
    padding-left: 1rem;
    list-style: disc;
  }
  
  .question strong {
    display: block;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .question p {
    font-size: 1rem;
    color: #555;
  }
  