.vacancy-details {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

.vacancy-details .vacancy-description pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Lato";
  color: #202020;

}

.vacancy-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  padding: 0px;
}

.header-content-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  margin-left: 15px;
  margin-top: 20px;
}


.header-content h2 {
  margin: 0;
  margin-right: 10px;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.header-content p {
  font-size: 18px;
  color: #787878;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.status-badge.active {
  background-color: #C7F4C4;
  color: #000;
}

.status-badge.inactive {
  background-color: #dc3545;
  color: white;
}

.header-actions {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.copy-link-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 20px;
  text-align: center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.delete-btn {
  cursor: pointer;
  padding-left: 15px;
}

.copy-link-btn:hover {
  background-color: #f0f0f0;
}

.vacancy-info {
  margin-top: 0px;
  margin-bottom: 20px;
}

.vacancy-details .vacancy-info p {
  margin: 5px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.vacancy-details .questions-section h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
}

.question-item {
  margin-bottom: 20px;
}

.question-item p {
  margin: 5px 0;
  font-size: 16px;
}

.question-item ul {
  list-style-type: none;
  padding-left: 0;
}

.question-item li {
  background: #f9f9f9;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  margin-bottom: 20px;
}

.vacancy-details .vacancy-questions {
  margin-bottom: 0;
}

.vacancy-description h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}