.company-details {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.company-overview {
  border-radius: 30px;
  padding: 20px;
}

.company-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.company-details-header h3 {
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.industry-badge {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
}

.no-data {
  text-align: center;
  padding: 40px;
}

.no-data h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.no-data p {
  color: #6c6c6c;
  margin-bottom: 20px;
}

.no-data-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-company-btn:hover {
  background-color: #0056b3;
}

.company-edit-button {
  display: flex;
  gap: 5px;
  cursor: pointer;
  margin-top: 7px;
}

.company-edit-button p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.company-overview
label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.company-overview
input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  pointer-events: none;
}

.non-editable-textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  white-space: pre-wrap; /* Preserves whitespace and line breaks */
  overflow: hidden; /* Prevent scrolling */
  height: auto; /* Adjust height as needed */
  color: #333; /* Text color */
  font-family: 'Lato';
  font-size: 14px; /* Font size */
  margin: 0 0 20px 0; /* Margin like a textarea */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in width */
}

.company-logo {
  max-width: 100%;
  max-height: 100px;
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.portfolio-section {
  margin-bottom: 30px;
}

.portfolio-section h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.portfolio-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  white-space: pre-wrap; /* Preserves whitespace and line breaks */
  overflow: hidden; /* Prevent scrolling */
  height: auto; /* Adjust height as needed */
  color: #333; /* Text color */
  font-family: 'Lato';
  font-size: 14px; /* Font size */
  margin: 0 0 20px 0; /* Margin like a textarea */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in width */
}

.portfolio-item h5 {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.portfolio-item p {
  margin: 0;
  color: #555;
  font-size: 14px;
  line-height: 1.5;
}

.new-company {
  overflow-y: auto;
  padding: 20px;
}

.new-company input[type="text"] {
  pointer-events: all;
}