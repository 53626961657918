/* src/components/NewVacancy.css */

.new-vacancy-page {
  width: 100%;
  height: calc(100vh - 110px);
  background-color: #ffffff;
  border-radius: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.new-vacancy-page.shake {
  animation: shake 0.5s;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }
}

.vacancy-sidebar {
  width: 250px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.vacancy-sidebar h2 {
  margin-bottom: 20px;
}

.vacancy-sidebar ul {
  list-style: none;
  padding: 0;
}

.vacancy-sidebar li {
  margin-bottom: 10px;
  color: #888;
}

.vacancy-sidebar li.active {
  font-weight: bold;
  color: #333;
}

.vacancy-main h2 {
  margin-bottom: 10px;
}

.vacancy-main p {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.button-group button,
.upload-btn {
  flex: 1;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-bottom: 20px;
}

.upload-btn {
  display: inline-block;
  text-align: center;
}

.warning {
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
}

.loading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-spinner {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.aigen-btn,
.upload-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  color: #000000;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s, border-color 0.3s;
  outline: none;
}

.aigen-btn {
  border: 1px solid var(--Primary-blue, #0097F5);
  border-radius: 30px;
}

.aigen-btn:hover,
.upload-btn:hover {
  background-color: #fff;
  border-color: #0056b3;
}

.aigen-btn:disabled,
.upload-btn:disabled {
  background-color: #fff;
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.upload-btn {
  padding: 8px 12px; /* Adjust for a more compact appearance */
}

.upload-btn input[type="file"] {
  display: none;
}

.jd-button-group {
  display: flex;
  gap: 15px;
}

.next-step-btn {
  width: 10rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s;
  outline: none;
}

.next-step-btn:hover {
  background-color: #0056b3;
}

.next-step-btn:disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}

.previous-step-btn {
  width: 10rem;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s;
  border: 1px solid var(--Primary-blue, #0097F5);
  background-color: #fff;
}

.previous-step-btn:hover {
  background-color: #ccc;
  border-color: #0056b3;
}

.previous-step-btn:disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}

.vacancy-form {
  display: flex;
  width: auto;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}

.vacancy-form .button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.vacancy-form .button-group .upload-btn {
  background: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.vacancy-form .button-group button {
  background: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.vacancy-form .button-group button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.vacancy-form .loading-message {
  font-size: 1
}

.form-header h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 20px;

}

.form-header p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
}