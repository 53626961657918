.form-list p {
  margin-bottom: 16px;
}

.gripper-icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  cursor: pointer;
  flex-shrink: 0;
}

.form-question-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-question-content {
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 8px;
  width: 100%;
}

.form-questions-section h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.form-question-item p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.form-question-item ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

.form-question-item li {
  margin: 5px 0;
  padding-bottom: 8px;
}