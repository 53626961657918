.profile-sidebar {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.personal-info {
  margin-bottom: 20px;
  /* Space between sections */
}

.profile-sidebar-header {
  margin-bottom: 20px;
}

.profile-header-content {
  display: flex;
  align-items: center;
}

.profile-image-placeholder {
  width: 150px;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin-right: 15px;
}

.profile-text-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-name {
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-role {
  margin: 0;
  color: var(--grey-text, #686868);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.personal-info-title {
  display: flex;
  justify-content: space-between;
}

.personal-info-title h4 {
  margin: 0;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.personal-info-title svg {
  margin-top: 10px;
  cursor: pointer;
}

.personal-info-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.personal-info-birthday .personal-info-birthday-age {
  color: var(--grey-text, #686868);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.personal-info-item p {
  color: var(--black-text, #2E2E2E);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.personal-info-birthday p {
  padding: 0;
  margin: 0;
}

.personal-info-icon-background {
  background-color: #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.social-media-links {
  margin-top: 20px;
}

.social-media-links h4 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.social-media-icons {
  display: flex;
  gap: 15px;
}

.social-media-icons svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #686868; 
  transition: fill 0.3s;
}

.social-media-icons svg:hover {
  fill: #000; 
}

.profile-cv {
  margin-top: 20px;
}

.profile-cv h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.cv-file {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--grey-text, #686868);
  font-size: 18px;
}

.cv-file p {
  margin: 0;
}

.cv-file svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #686868;
  transition: fill 0.3s;
}

.cv-file svg:hover {
  fill: #000; 
}

