.profile-details {
    flex: 4;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-left: 20px; /* Spacing between sidebar and details */
  }
  
  .experience-section,
  .background-section,
  .skills-section {
    margin-bottom: 30px; /* Spacing between sections */
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
  }
  