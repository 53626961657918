.interview-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.chat-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.chat-box {
  flex-grow: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: auto;
  overflow: hidden;
  max-height: calc(90vh - 20px);
  border-radius: 30px;
  background-color: #FFF;
}

.chat-messages {
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px 20px;
  gap: 10px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  box-sizing: border-box;
}

.message {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0px;
  border-radius: 0px 20px 20px 20px;
  max-width: 100vh;
  overflow-wrap: break-word;
  font-size: 18px;
}

.chat-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.progress-title {
  font-size: 14px;
  color: #666;
  text-align: left;
  margin-bottom: 5px;
  margin: 0px 20px;
}

.progress-bar {
  width: auto;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin: 5px 20px 20px 20px;
}

.progress {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.split-message {
  margin-bottom: 7.5px;
  /* Half of the default gap */
}

.message>* {
  margin: 0;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 10px;
}

.agent-message {
  background: #F5F5F5;
  color: black;
  align-self: flex-start;
}

.user-message {
  background-color: #fff;
  color: black;
  align-self: flex-start;
  box-shadow: 0 0 0 1px #D1D1D1;
  /* Simulates a 2px border */
}

.message-box {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 1rem;
  border: 1px solid var(--light-grey-stroke, #EBEBEB);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  max-height: 100%;
}

.message-box textarea {
  flex: 1 1 auto;
  border: none;
  margin: 3px;
  padding: 0;
  padding-top: 10px;
  padding-left: 8px;
  color: #333;
  overflow-y: auto;
  resize: none;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;
  max-height: 200px;
  box-sizing: border-box;
}

.message-box textarea:focus {
  outline: none;
  /* Removes the default outline */
}


.message-box-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
}

.message-box-icons button,
.message-box-icons .custom-file-upload {
  border: none;
  background: transparent;
  cursor: pointer;
}

.custom-file-upload svg {
  width: 24px;
  height: 24px;
}

.custom-send-icon {
  width: 24px;
  height: 24px;
}


.custom-file-upload,
.custom-send-icon {
  margin: 0px;
}

.message-box-icons button:disabled {
  cursor: not-allowed;
}

.interview-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;
}

.link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  margin-top: 0px;
  margin-left: 8px;
  margin-bottom: 8px;
}

.button-group button.decline:hover {
  cursor: pointer;
}

.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  flex: none;
  width: auto;
}

.button-group button.decline {
  background-color: #fff;
  color: black;
  border: solid 1px #007bff;
}

.button-group button.accept {
  background-color: #007bff;
  color: white;
}

.cv-message {
  color: grey;
}

.dev-reset-btn {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-right: 20px;
}

.dev-reset-btn:hover {
  background-color: darkred;
}

.hidden-input {
  display: none;
}

.hidden-input:disabled {
  cursor: not-allowed;
}

.message ol,
.message ul {
  padding-left: 20px;
  margin: 0;
}

.message ol li,
.message ul li {
  margin-bottom: 10px;
  /* Space between list items */
}


/* MOBILE */
@media (max-width: 768px) {
  .interview-page {
    background-color: #fff;
    min-height: 100dvh;
  }

  .chat-box {
    width: 100%;
    max-height: calc(100dvh - 50px);
    margin: 0;
  }

  .chat-title {
    margin: 10px;
  }

}