/* src/components/newVacancyFlow/JobDescriptionStep.css */

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.jd-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}

.form-jd-header h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 0px;

}

.form-jd-header p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
}