.candidates-page {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 2rem;
  background-color: #f5f5f5;
  height: calc(100vh - 110px);
}

.candidates-main {
  flex: 3 1;
  border-radius: 20px;
  margin-left: 20px;
}

.candidate-details {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
}
.radar-chart-container {
  width: 50vw;
  height: 35vh;
  overflow: hidden;  
}
.radar-chart-container canvas {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;  
}

.evaluation-scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 10px 15px 10px 10px;
}

.skills-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 10px 15px;
}

.candidate-contacts {
  flex: 1 0 auto;
  /* Ensure contacts take the available space */
  margin-bottom: 10px;
  /* Add margin for spacing */
  justify-content: flex-end;
}

.candidate-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.candidate-info p {
  flex: 1 1 45%;
  /* Adjust flex properties to ensure even distribution */
  margin-bottom: 10px;
  box-sizing: border-box;
}

.candidate-contacts,
.candidate-location,
.candidate-position,
.candidate-education,
.candidate-skills,
.candidate-certifications,
.candidate-portfolio,
.candidate-experience,
.candidate-languages {
  flex: 1 1 45%;
  /* Ensure all elements take up 45% of the width */
}

.no-candidates-message {
  color: #888;
}

.interview-details {
  text-align: left;
}

.interview-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

.interview-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.interview-info {
  margin-bottom: 10px;
  padding: 10px;
}

.no-data {
  text-align: center;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.interview-details {
  margin-top: 20px;
}

.interview-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.content-box {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.go-back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  max-width: 60%;
  word-wrap: break-word;
}

.agent {
  background-color: #f0f0f0;
  color: black;
  align-self: flex-end;
}

.user {
  background-color: #d0e7ff;
  color: black;
  align-self: flex-start;
}

.evaluation-content {
  display: flex;
  flex-direction: column;
}

.evaluation-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.evaluation-report .evaluation-item h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.evaluation-report .evaluation-item strong {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.evaluation-report .evaluation-item p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}

.evaluation-report .evaluation-headers strong {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.evaluation-report .evaluation-headers p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.placeholder-text {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.placeholder-text h3 {
  margin-top: 0;
}

.placeholder-text span {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.edit-candidate-box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.edit-candidate-box label {
  display: block;
  margin-bottom: 10px;
}

.edit-candidate-box input[type="text"],
.edit-candidate-box textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  box-sizing: border-box;
}

.edit-candidate-box button {
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-candidate-box button:last-child {
  background-color: #ccc;
  color: black;
}

.evaluation-overview {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.score-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.score-circle p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.radar-chart-placeholder {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ccc;
  margin: 0 20px;
  height: 300px;
}

.skills-list p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.evaluation-summary {
  margin-top: 20px;
  text-align: left;
  padding: 10px;
}

.evaluation-summary h3 {
  margin-bottom: 10px;
}

.evaluation-summary span {
  font-size: 1.5em;
  font-weight: bold;
}

.interview-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 20px;
  text-align: center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
}

.interview-buttons-container {
  margin-top: 10px;
}

.candidate-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.candidate-contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}

.candidate-contacts-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.candidate-contacts span {
  display: flex;
  align-items: center;
}

.candidate-contact-icon {
  margin-right: 5px;
}

.candidate-name-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
}

.candidate-position-subtitle {
  color: #666;
  margin: 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.candidate-location {
  display: flex;
  justify-content: right;
}

.candidate-location p {
  margin-top: 5px;
  margin-bottom: 0px;
}

.card {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  margin-bottom: 20px;
}

.inviscard h2 {
  font-size: 36px;
}

.candidate-personal-information h2,
.candidate-evaluation h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.candidate-interview-details h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 15px;
}

.vacancy-eval-back-btn {
  display: flex;
  margin-bottom: 0px;
}

.vacancy-eval-back-btn p {
  cursor: pointer;
}

.eval-report-header {
  display: flex;
  justify-content: space-between;
}

.eval-report-header .left-side {
  display: flex;
  gap: 10px;
  margin: 0;
  padding-top: 15px;
}

.eval-report-header .right-side h4 {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.eval-report-header svg {
  width: 10px;
}

.eval-report-header .dot {
  margin: 0;
  padding-top: 0.8rem;
}

.eval-report-header p {
  margin: 0;
  padding-top: 0.19rem;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #787878;
}

.eval-report-header h4 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.back-arrow-icon {
  width: 20px;
  height: 20px;
  padding-top: 16px;
  margin-right: 5px;
  cursor: pointer;
}

.evaluation-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.evaluation-report .evaluation-headers .evaluation-chathistory {
  color: #787878;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.evaluation-report {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.evaluation-report h4 {
  margin-top: 0px;
}

/* Overlay background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

/* Modal content */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  /* Scrollable content */
  position: relative;
}

/* Close button */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Title */
.popup-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Content styles */
.popup-content-text {
  white-space: pre-wrap;
  /* Preserves whitespace but allows wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 1.6;
}