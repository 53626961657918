.personal-information {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  margin-bottom: 20px;
}

.personal-information .header {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.personal-information .subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #787878;
}

.personal-information .detail-head {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #202020;
}

.personal-information h2 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
}

.personal-info-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 100px;
}

.portfolio-and-education,
.skills-and-experience {
  flex: 1;
}

.portfolio-and-education>div,
.skills-and-experience>div {
  margin-bottom: 10px;
}

.personal-information ul {
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 0;
}

.personal-information ul li {
  margin-bottom: 10px;
  margin-left: 20px;
}

/* li {
    margin-top: 10px;
  } */

.education,
.experience {
  flex: 1;
  margin-top: 30px;
}

.education ul,
.experience ul {
  padding-left: 0;
}

.education ul li,
.experience ul li {
  margin-bottom: 5px;
}

.education ul li div,
.experience ul li div {
  color: #888;
  font-size: 0.9em;
}

.strong {
  font-weight: bold;
}