.interview-details {
  padding: 0;
  margin: 0;
}

.interview-details h2 {
  margin-bottom: 20px;
}

.interview-list {
  list-style-type: none;
  padding-left: 0;
}

.interview-item {
  background-color: #fff;
  border-radius: 32px;
  border-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 0;
}

.interview-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.interview-header h3 {
  margin: 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.interview-date {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #787878;
}

.interview-overview h4 {
  margin: 20px 0 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.interview-overview p {
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #333;
}

.interview-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.interview-score {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #333;
}

.evaluation-report-link {
  background: none;
  border: none;
  padding: 0;
  color: #007BFF;
  cursor: pointer;
  text-decoration: none;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.evaluation-report-link:hover {
  text-decoration: underline;
}

.interview-details-footer {
  display: flex;
}

.show-more-button {
  display: 1;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
}

.show-more-button:hover {
  background-color: #0056b3;
}