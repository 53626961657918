.edit-company-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 32px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 100%;
  height: 100%;
  margin-top: 100px;
}

.form-group {
  margin-bottom: 15px;
}

.company-overview .form-group .company-overview-enabled {
  pointer-events: all;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.key-value {
  gap: 10px;
  width: auto;
}

.key-value button {
  background-color: #fff;
  border: 1px solid #007bff;
  color: #000;
  border-radius: 32px;
  cursor: pointer;
  flex: none;
  padding: 10px 20px;
  width: 20%;
}

.add-achievement-btn {
  background-color: #fff;
  border: 1px solid #007bff;
  color: #000;
  border-radius: 32px;
  cursor: pointer;
  flex: none;
  padding: 10px 20px;
  width: 25%;
  margin-top: 10px;
}

.add-socials-btn {
  background-color: #fff;
  border: 1px solid #007bff;
  color: #000;
  border-radius: 32px;
  cursor: pointer;
  flex: none;
  padding: 10px 20px;
  width: 25%;
}

.value-list button,
.benefit-list button,
.product-list button,
.service-list button,
.partnership-list button,
.achievement-list button,
.location-list button,
.social-media-list button {
  background-color: #fff;
  border: 1px solid #007bff;
  color: #000;
  border-radius: 32px;
  cursor: pointer;
  flex: none;
  padding: 5px 20px;
  margin-top: 2px;
  margin-left: 10px;
}


.form-actions {
  display: flex;
  justify-content: flex-end;
}

.save-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  width: 25%;
}

.save-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 25%;
}

.company-contact {
  padding-bottom: 0;
  margin-bottom: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  /* Greyed-out background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Ensure it appears above other elements */
}

.form-group .input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.9em;
}