.new-company-page {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.company-sidebar-new-header {
  padding: 20px 30px;
}

.companies-sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.company-eval-back-btn {
  display: flex;
  margin-bottom: 0px;
}

.company-eval-back-btn p {
  cursor: pointer;
}

/* START OF NEW company DYNAMIC LIST */

.company-sidebar-new-header {
  padding: 20px 30px;
}

.company-sidebar-new-header h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 30px;
}

.company-sidebar-new-header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.company-sidebar-new-header li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  /* Larger font size for the steps */
  font-weight: 500;
  color: #888;
  /* Default gray color */
  position: relative;
  transition: color 0.3s;
}

.company-sidebar-new-header li.active {
  color: #000;
  /* Active step color */
}

.company-sidebar-new-header li:before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee;
  /* Default circle background */
  text-align: center;
  line-height: 40px;
  /* Center text vertically */
  margin-right: 10px;
  /* Space between number and text */
  font-size: 16px;
  color: #fff;
}

.company-sidebar-new-header li.active:before {
  background-color: #DAE9FE;
  /* Active circle background */
  color: #000;
}

.company-sidebar-new-header li.success {
  cursor: default;
  /* Remove pointer cursor */
  color: #888;
  /* Same default color */
}

.company-sidebar-new-header li:nth-child(1):before {
  content: '1';
}

.company-sidebar-new-header li:nth-child(2):before {
  content: '2';
}

.company-sidebar-new-header li:nth-child(3):before {
  content: '3';
}

.company-sidebar-new-header li:nth-child(4):before {
  content: '4';
}

/* END OF NEW company DYNAMIC LIST */

.logo-container {
  border-radius: 20px;
  border: 1px dashed var(--grey-stroke, #BEBEBE);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 150px;
}

.logo-container .logo-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.logo-container .logo-placeholder h3 {
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 17px 0px 5px 0px;
  color: #303030;
}

.logo-container .logo-placeholder p {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #787878;
}

.new-company-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  gap: 5px;
}

.create-company-overview .form-row {
  margin-bottom: 0;
}

.company-values-and-culture,
.company-contact,
.company-business-portfolio {
  display: flex;
  flex-direction: column;
}

.addable-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.addable-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.addable-content {
  flex-grow: 1;
  color: #202020;
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 15px;
}

.addable-content strong {
  display: block;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.addable-content p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.addable-item .delete-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
}