.candidates-sidebar {
    flex: 1 1;
    min-width: 350px;
    max-width: 400px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 110px);
    overflow: hidden;
}

.candidates-sidebar-header {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 20px 30px;
}

.candidates-sidebar-header h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0px;
}

.candidate-list {
    flex-grow: 1;
    list-style-type: none;
    padding-left: 0;
    margin-top: 20px;
    overflow-y: auto;
    /* REMOVE SCROLL BAR */
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.sidebar-interactives {
    display: flex;
    gap: 10px;
}

/* REMOVE SCROLL BAR  */
.candidate-list::-webkit-scrollbar {
    display: none;
}

.candidate-item {
    padding: 20px 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0px;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
}

.candidate-item.selected {
    background-color: #F4F4F4;
    border-bottom: 1px solid var(--light-grey-stroke, #EBEBEB);
    border-top: 1px solid var(--light-grey-stroke, #EBEBEB);
}

.candidate-name {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.candidate-content {
    flex-grow: 1;
    padding-left: 30px;
}

.no-candidates-message {
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    text-align: center;
}