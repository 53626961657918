/* src/App.css */

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.app {
  font-family: "Lato";
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.content {
  position: relative;
  background-color: #ECECEC;
  flex: 1;
  display: flex;
}

.page-transition {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 5px;
}


.navbar-center {
  display: flex;
  text-align: center;
  gap: 3rem;
  margin-right: calc(15%);
}

.navbar-right {
  position: relative;
  display: flex;
  padding-right: 20px;
}

.navbar-right svg {
  cursor: pointer;
}

.logo {
  height: 32px;
  margin-left: 100px;
  padding: 3px 3px 3px 0;
}

@media screen and (max-width: 768px) {
  .logo {
    height: 32px;
    margin-left: 16px;
    padding: 3px 3px 3px 0;
  }
}

.profile-icon {
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.nav-link {
  text-decoration: none;
  color: #3D3D3D;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nav-link.active {
  color: #202020;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.vacancies-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vacancies-content button {
  margin-top: 10px;
}

/* Fade transition classes */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* Centralized Dropdown Styles */
.dropdown-container {
  position: relative;
}

.dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
  top: 100%;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-select:focus {
  outline: none;
  border-color: #007bff;
}

.delete-item {
  color: red;
}

.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  width: 15px;
  height: 15px;
  pointer-events: none;
}

.search-bar {
  padding: 10px;
  padding-left: 30px;
  padding-top: 12px;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--light-grey-stroke, #EBEBEB);

  }

  .navbar-center {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .nav-link {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}