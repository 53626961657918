/* src/components/newVacancyFlow/SuccessStep.css */

.success-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  height: 100%;
}

.success-step h2 {
  font-size: 2rem;
  color: #333;
  margin: 0.5rem;
}

.success-step p {
  font-size: 1.25rem;
  color: #555;
  margin: 0.5rem;
}

.success-step a {
  font-size: 1rem;
  color: #202020;
  word-break: break-all;
  margin: 1rem 0;
}

.success-step button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  color: #000000;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s, border-color 0.3s;
  outline: none;
  border: 1px solid var(--Primary-blue, #0097F5);
  border-radius: 30px;
  margin: 0.8rem;
}

.success-step button:hover {
  background-color: #fff;
  border-color: #0056b3;
}