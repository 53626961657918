.vacancies-page {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 2rem;
  background-color: #f5f5f5;
  height: calc(100vh - 110px);
}

.vacancies-main {
  flex: 3 1;
  border-radius: 20px;
  margin-left: 20px;
}

.create-new-btn {
  background-color: #fff;
  border: 1px solid #007BFF;
  border-radius: 30px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  outline: none;
  transition: all 0.3s;
}

.dropdown-select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.no-vacancies-message {
  color: #888;
}



.no-data {
  text-align: center;
}

.create-vacancy-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

.dropdown-select {
  width: 25%;
}