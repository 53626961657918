.edit-vacancy-form {
  display: flex;
  flex-direction: column;
}

.edit-vacancy-form label {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.edit-vacancy-form input[type="text"],
.edit-vacancy-form textarea,
.edit-vacancy-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
}

.edit-vacancy-form textarea {
  resize: vertical;
}

.edit-vacancy-form .buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-vacancy-form .buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-vacancy-form .buttons button[type="button"] {
  background-color: #007bff;
  color: white;
}

.edit-vacancy-form .buttons button[type="button"]:last-child {
  background-color: #ccc;
  color: black;
}
