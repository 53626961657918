.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-otp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    position: relative;
}

.modal-otp-input {
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 20px 0 0 20px;
    padding: 10px;
    width: 40%;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    position: relative;
    z-index: 1;
}

.modal-otp-input:focus {
    border-color: #007bff;
    outline: none;
}

.modal-otp-button {
    background-color: #007bff;
    border: 1px solid #007bff;
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: -30px;
    z-index: 2;
    position: relative;
}

.modal-otp-button-normal {
    background-color: #007bff;
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-otp-button-empty {
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-otp-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.modal-otp-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.custom-file-upload.namemismatch {
    margin-left: -60px;
    margin-right: 30px;
    z-index: 3;
}

/* old styles */
.modal-otp-buttons button.decline {
    background-color: white;
    color: black;
    border: solid 1px #007bff;
}


.modal-otp-buttons button {
    border: none;
    border-radius: 32px;
    flex: none;
    width: auto;
}

.modal-subtitle {
    font-size: 14px;
    color: grey;
}

.modal-otp-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    width: 80%;
    max-width: 500px;
}

.modal-content h2 {
    margin-top: 0;
}