.vacancies-sidebar {
  flex: 1 1;
  min-width: 350px;
  max-width: 400px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  overflow: hidden;
}

.vacancy-list {
  flex-grow: 1;
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  overflow-y: auto;
  /* REMOVE SCROLL BAR */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* REMOVE SCROLL BAR  */
.vacancy-list::-webkit-scrollbar {
  display: none;
}


.vacancies-sidebar-header {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 20px 30px;
}

.vacancies-sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.vacancies-sidebar-title h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0px;
}

/* START OF NEW VACANCY DYNAMIC LIST */

.vacancy-sidebar-new-header {
  padding: 20px 30px;
}

.vacancy-sidebar-new-header h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 30px;
}

.vacancy-sidebar-new-header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vacancy-sidebar-new-header li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  /* Larger font size for the steps */
  font-weight: 500;
  color: #888;
  /* Default gray color */
  position: relative;
  transition: color 0.3s;
}

.vacancy-sidebar-new-header li.active {
  color: #000;
  /* Active step color */
}

.vacancy-sidebar-new-header li:before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee;
  /* Default circle background */
  text-align: center;
  line-height: 40px;
  /* Center text vertically */
  margin-right: 10px;
  /* Space between number and text */
  font-size: 16px;
  color: #fff;
}

.vacancy-sidebar-new-header li.active:before {
  background-color: #DAE9FE;
  /* Active circle background */
  color: #000;
}

.vacancy-sidebar-new-header li.success {
  cursor: default;
  /* Remove pointer cursor */
  color: #888;
  /* Same default color */
}

.vacancy-sidebar-new-header li:nth-child(1):before {
  content: '1';
}

.vacancy-sidebar-new-header li:nth-child(2):before {
  content: '2';
}

.vacancy-sidebar-new-header li:nth-child(3):before {
  content: '3';
}

.vacancy-sidebar-new-header li:nth-child(4):before {
  content: '4';
}

/* END OF NEW VACANCY DYNAMIC LIST */

.dropdown-select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.vacancy-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
}

.vacancy-item.selected {
  background-color: #F4F4F4;
  border-bottom: 1px solid var(--light-grey-stroke, #EBEBEB);
  border-top: 1px solid var(--light-grey-stroke, #EBEBEB);
}

.vacancy-content {
  flex-grow: 1;
  padding-left: 30px;
}

.vacancy-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.vacancy-company {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #555;
}

.vacancy-active {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #555;
}

.no-vacancies-message {
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  text-align: center;
}

.search-and-filter {
  gap: 10px;
}

.search-and-filter .bottom-section {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dropdown-select {
  width: 25%;
}

.search-and-filter .search-bar {
  width: 100%;
}

.filter-btn {
  flex-grow: 1;
  height: 39px;
  padding: 10px;
  border: 1px solid #ddd !important;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #888;
  font-size: 14px;
  text-transform: none;
  text-align: left;
}

.filter-btn:hover {
  border-color: #ccc;
  /* Slightly darker border on hover */
}

.filter-btn .MuiButton-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filter-btn span {
  flex-grow: 1;
  text-align: left;
  padding-right: 10px;
  text-transform: none;
  color: #858585;
  font-size: 14px;
}

.filter-btn::after {
  content: '▼';
  /* Add a dropdown arrow icon */
  font-size: 12px;
  margin-left: auto;
  /* Push the arrow to the far right */
  color: #888;
}