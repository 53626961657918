.companies-sidebar {
  flex: 1 1;
  min-width: 350px;
  max-width: 400px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  overflow: hidden;
}

.company-list {
  flex-grow: 1;
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  overflow-y: auto;
  /* REMOVE SCROLL BAR */
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

/* REMOVE SCROLL BAR  */
.company-list::-webkit-scrollbar {
  display: none;
}


.companies-header {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 20px 30px;
}

.companies-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.companies-header-title h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0px;
}

.company-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
}

.company-item.selected {
  background-color: #F4F4F4;
  border-bottom: 1px solid var(--light-grey-stroke, #EBEBEB);
  border-top: 1px solid var(--light-grey-stroke, #EBEBEB);
}

.company-content {
  flex-grow: 1;
  padding-left: 30px;
}

.company-name {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.company-industry {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #555;
}

.no-companies-message {
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  text-align: center;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-btn {
  cursor: pointer;
}