.login-page {
    width: 100%;
    display: flex;
    background: #fff;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.login-container {
    width: 45%;
    padding: 20px 10% 20px 10%;
}

.slideshow-container {
    height: 100%;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slideshow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slide-image {
    width: 100%;
    max-width: 983px;
    height: auto;
    max-height: calc(100vh - 110px);

}

.slide-indicators {
    margin-top: 10px;
}

.indicator {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s;
    cursor: pointer;
}

.indicator.active {
    background-color: #0097F5;
    width: 20px;
    border-radius: 10px;
}

.login-page .login-form {
    width: 100%;
}

.login-page .login-form h2 {
    margin-bottom: 10px;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login-page .login-form p {
    color: #787878;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 25px;
}

.login-page .login-form a {
    color: #3D3D3D;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    margin-left: 5px;
}

.login-page .login-form a:hover {
    text-decoration-line: none;
}

.login-form .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    position: relative;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login-form .separator::before,
.login-form .separator::after {
    content: '';
    flex: 1;
    height: 1px;
    background: #ccc;
    margin: 0 10px;
}

.forgot-password {
    margin-bottom: 30px;
}

.login-form .forgot-password a {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    text-decoration-line: none;
}

.login-form .forgot-password a:hover {
    text-decoration-line: underline;
}

.login-submit-btn {
    align-self: center;
    width: 100%;
    padding: 12px;
    background-color: #0097F5;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.login-submit-btn:hover {
    background-color: #0056b3;
}

.slide-content {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.image-container {
    position: relative;
    display: inline-block;
}


.slide1-content {
    text-align: center;
    height: 95%;
    background-color: #e0f7fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px;
}

.slide1-title-container {
    position: absolute;
    top: 0px;
    left: -20px;
    /* Adjust as needed */
    text-align: left;
    z-index: 1;
    /* Ensure it sits above the image */
}


.slide1-title-kiwi {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 20px;
}

.slide1-subtitle {
    color: #3D3D3D;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.kiwi-image {
    max-width: 600px;
    height: auto;
    margin-top: 100px;
}

@media screen and (max-width: 1550px) {
    .slide-content .mob-title-container {
        left: 0px !important;
    }
}

@media screen and (max-width: 1165px) {
    .slide-content .mob-title-container {
        top: -40px !important;
        left: 40px !important;
        width: 400px !important;
    }

    .slide-content .slide2 {
        top: -100px !important;
        left: 40px !important;
        width: 400px !important;
    }

    .slide-content .mob-img-slide1,
    .slide-content .mob-img-slide2,
    .slide-content .mob-img-slide3 {
        max-width: 450px !important;
    }
}


/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
    .login-page {
        flex-direction: column;
        padding: 20px;
        width: calc(100dvw - 40px);
        min-height: 100dvh;
    }

    .slide-content .mob-img-slide1,
    .slide-content .mob-img-slide2,
    .slide-content .mob-img-slide3 {
        max-width: 230px !important;
        height: auto !important;
        margin-top: 80px !important;
    }

    .slide-content .mob-contain-slide1,
    .slide-content .mob-contain-slide2,
    .slide-content .mob-contain-slide3 {
        text-align: center !important;
        height: 300px !important;
        width: 330px !important;
        background-color: #e0f7fa !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        border-radius: 20px !important;
        padding: 15px 8px 15px 8px !important;
    }

    .slide-content .mob-title-container {
        width: 320px !important;
        top: -0px !important;

        left: -40px !important;
    }

    .slide-content .mob-contain-slide2 .mob-title-container {
        top: -39px !important;
    }

    .slide-content .mob-contain-slide3 .mob-title-container {
        top: 8px !important;
    }


    .slide-content .mob-title-slide1,
    .slide-content .mob-title-slide2,
    .slide-content .mob-title-slide3 {
        font-family: Lato !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        margin: 0 !important;
        margin-bottom: 10px !important;
        display: flex !important;
        align-items: center !important;
    }

    .slide-content .mob-subtitle-slide1,
    .slide-content .mob-subtitle-slide2,
    .slide-content .mob-subtitle-slide3 {
        font-family: Lato !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        color: #3D3D3D !important;
        margin: 0 !important;
    }

    /* LOGIN FORM */

    .login-container {
        flex: 1;
        padding: 5px 20px;
        width: 100%;
    }

    .login-form .separator {
        display: none;
    }

    .login-page .login-form h2 {
        margin-top: 0px;
    }

    /* Adjust .slideshow-container for mobile */
    .slideshow-container {
        width: auto;
        flex: unset;
        height: auto;
    }

    /* Adjust .slideshow for mobile */
    .slideshow {
        flex: unset;
        height: auto;
    }

}